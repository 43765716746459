import { createRouter, createWebHistory } from 'vue-router';

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue'),
        children: [{
                name: 'Tasks',
                path: '',
                component: () =>
                    import ('../components/Abouts/Tasks.vue'),
            },
            {
                name: 'Principles',
                path: '',
                component: () =>
                    import ('../components/Abouts/Principles.vue'),
            },
            {
                name: 'Structure',
                path: '',
                component: () =>
                    import ('../components/Abouts/Structure.vue'),
            },
        ],
    },
    {
        path: '/services',
        name: 'Services',
        component: () =>
            import ('../views/Services.vue'),
    },
    {
        path: '/Terms',
        name: 'Terms',
        component: () =>
            import ('../views/Terms.vue'),
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue'),
    },
];

let aboutSection = ['Tasks', 'Principles', 'Structure']

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (!aboutSection.includes(to.name))
            return { top: 0 };
    },
});

export default router;