<template>
	<Nav />
	<router-view />
	<Footer />
</template>
<script>
import { defineAsyncComponent } from 'vue';
const Nav = defineAsyncComponent(() => import('./components/Nav.vue'));
const Footer = defineAsyncComponent(() => import('./components/Footer.vue'));
export default {
	name: 'App',
	data() {
		return {};
	},
	components: {
		Nav,
		Footer,
	},
};
</script>
<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
</style>
