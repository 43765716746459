import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import AOS from 'aos';
import { VuelidatePlugin } from "@vuelidate/core";
import mapboxgl from "mapbox-gl";

// import { ValidationProvider } from 'vee-validate';
import 'swiper/swiper.scss';
import 'aos/dist/aos.css';
import 'swiper/components/pagination/pagination.scss';
import './main.css';
// import 'swiper/components/effect-fade/effect-fade.scss';

AOS.init();

mapboxgl.accessToken =
    "pk.eyJ1IjoiZmFyYWpzaHVhaWIiLCJhIjoiY2tuMjFoeW1wMHJyODJwcGJscTllb29hMSJ9.bzjQzcDqVg9yG2fMehyLhw";

mapboxgl.setRTLTextPlugin(
    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
    null,
    true // Lazy load the plugin
);

const vue = createApp(App);
vue.use(router);
vue.use(VuelidatePlugin)
vue.mount('#app');